import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'
// import ShopCategory from "../components/ecwid/category"

class ContactUs extends React.Component {
	componentDidMount() {
		if (window) {
			const script = document.createElement('script')
			script.type = 'text/javascript'
			script.src = `https://forms.webriq.com/js/initForms`
			const headScript = document.getElementsByTagName('script')[0]
			headScript.parentNode.insertBefore(script, headScript)
		}
	}
	render() {
		let params = this.props.location.pathname

		return (
			<Layout
				pathname={params}
				head={{
					name: 'Contact Us',
					meta: {
						description:
							'Contact Us - Buck Woodcraft is open from 9a.m. to at least 4p.m. during business days. Were also in the shop most Saturdays for at least part of the day.',
					},
				}}
			>
				<div className="container py-5">
					<div className="row">
						{/*<div className="col-md-4">
							<h2 className="section-heading">Categories</h2>
							<ShopCategory/>
						</div>*/}
						<div className="col-md-8 shop-items mx-auto">
							<div className="page-content">
								<h4 className="section-heading page-mt">Contact Us</h4>
								<p>
									Buck Woodcraft is open from 9a.m. to at least 4p.m. during
									business days. We&#39;re also in the shop most Saturdays for
									at least part of the day. And when we&#39;re here and not
									already occupied with customers, we try to answer all calls
									and respond to emails as soon as possible.
								</p>
								<p>So if you need to get in touch, here&#39;s how:</p>
								<p>
									<strong>Telephone: 305-743-4090</strong> -- You'll be talking
									with either John (the owner) or Phil (the gopher). John&#39;s
									the technical expert when it comes to custom requirements, CNC
									capabilities and the like, and Phil can field most basic
									questions about the products and services we offer on our
									website.
								</p>
								<p>
									<strong>email:</strong> Send us your email via the form shown
									below.
								</p>
								<p>
									<strong>Snail Mail:</strong> Buck Woodcraft, Inc., 120 49th
									St., Marathon, FL 33050
								</p>
								<form
									action="/thank-you"
									method="post"
									data-form-id="5c4989b18ba9a30a93a29ff5"
									webriq="true"
								>
									<div className="form-group">
										<label>Your Name:</label>
										<input
											className="form-control"
											type="text"
											name="name"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>Your Email: *</label>
										<input
											className="form-control"
											type="text"
											name="email"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>Your Phone Number with Area Code</label>
										<input
											className="form-control"
											type="text"
											name="phone-number"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>Your Message</label>
										<textarea
											className="form-control"
											rows="10"
											name="comments"
										/>
									</div>
									<div className="webriq-recaptcha mb-3" />
									<div className="form-group">
										<button
											className="btn btn-primary btn-block blue-btn"
											type="submit"
										>
											Submit
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default ContactUs

export const categorynodeQuery = graphql`
	query {
		allStrapiCategories(sort: { fields: id, order: ASC }) {
			edges {
				node {
					id
					name
					url
					subcategories {
						name
						url
					}
				}
			}
		}
	}
`
